/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React, { useEffect, useState } from 'react';
import {
    object, string, func, shape, arrayOf, array, bool,
} from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withStyles from '@material-ui/core/styles/withStyles';
import { bindActionCreators } from 'redux';
import { useUIDSeed } from 'react-uid';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReactHtmlParser from 'react-html-parser';
import Error from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons//Check';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import  GridList  from '@material-ui/core/GridList';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ItemImage from '../../Checkout/containers/CheckoutFood/components/Partials/ItemImage/ItemImage';
import { addToCartNewCartServices, clearAddToCartError } from '../../../../state/ducks/AddToCart/AddToCart-Actions';
import { getAddToCartErrorList, getAddToCartError } from '../../../../state/ducks/AddToCart/AddToCart-Selectors';
import { getCart } from '../../../../state/ducks/Cart/Cart-Actions';
import {
    getRecipientsExcludePassport,
} from '../../../../state/ducks/Common/Common-Selectors';
import { skuBuilder } from '../../../pages/Checkout/containers/CheckoutFood/components/Partials/Helper/Helper';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import WrapUpModal from '../WrapUpPage/Partials/WrapUpModal';

const styles = (theme) => ({
    root: {
        margin: '0',
    },
    container: {
        margin: '0 auto',
    },
    buttonContinue: {
        height: '30px',
        width: '100%',
        border: `1px solid ${theme.palette.slideOutMiniCartMainColor || theme.palette.cms?.primary || theme.palette.secondary.main}`,
        background: theme.palette.common.white,
        color: theme.palette.slideOutMiniCartMainColor || theme.palette.cms?.primary || theme.palette.secondary.main,
        borderRadius: 0,
        cursor: 'pointer',
        margin: '3px 0px 0px',
        fontWeight: '400',
        fontFamily: theme.palette.slideOutMiniCartFlashSaleFont || 'inherit',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        maxWidth: '96px',
        '&:hover': {
            border: `1px solid ${theme.palette.cms?.primary || theme.palette.secondary.main}`,
            background: theme.palette.common.white,
            color: theme.palette.cms?.primary || theme.palette.secondary.main,
        },
    },
    errorMsg: {
        fontSize: '0.688rem',
        alignItems: 'center',
        color: theme.palette.error.main,
        padding: '0.125rem 0',
        width: '100%',
        wordWrap: 'break-word',
        display: 'flex',
        fontWeight: '700',
        flexDirection: 'column',
        textAlign: 'center',
        '& svg': {
            width: '1.25rem',
        },
    },
    errorContainer: {
        position: 'absolute',
        top: '0.625rem',
        background: 'rgb(206 206 206)',
        opacity: '0.8',
    },
    flashDealHeading: {
        fontSize: '1em',
        margin: 0,
        padding: '15px 15px 0px',
        fontWeight: '400',
        color: theme.palette.cms?.primary || theme.palette.secondary.main,
    },
    flashDealMain: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    prodTitle: {
        fontSize: '12px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        minHeight: '35px',
        fontFamily: theme.palette.slideOutMiniCartFlashSaleFont || 'inherit',
    },
    salePrice: {
        color: theme.palette.pdp.salePrice || '#a1001a',
        fontWeight: 700,
        padding: '0 0 0 10px',
        fontSize: '12px',
        fontFamily: theme.palette.slideOutMiniCartFlashSaleFont || 'inherit',
    },
    defaultPriceCheckoutRecommendation: {
        fontWeight: '400',
        right: '72px',
        fontSize: '12px',
        color: theme.palette.slideOutMiniCartMainColor || theme.palette.cms?.primary || theme.palette.secondary.main,
        fontFamily: theme.palette.slideOutMiniCartFlashSaleFont || 'inherit',
    },
    defaultPrice: {
        fontWeight: '500',
        right: '72px',
        fontSize: '12px',
        padding: '0 0 0 10px',
        color: theme.palette.slideOutMiniCartMainColor || theme.palette.contentColor,
        fontFamily: theme.palette.slideOutMiniCartFlashSaleFont || 'inherit',
    },
    orderItemImg: {
        padding: '5px 10px',
    },
    setItemLayout: {
        fontSize: '12px',
        padding: '2px 0',
        display: 'inline-block',
    },
    closeButtonCheckoutRecommendation: {
        fontSize: '1em',
        borderRadius: 0,
        padding: '12px 18px 10px 0px',
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            width: '20px',
            fill: theme.palette?.primary?.main || '#2F2F2F',
        },
    },
    closeButton: {
        fontSize: '1em',
        borderRadius: 0,
        padding: '12px 18px 10px 0px',
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            width: '15px',
            fill: theme.palette?.primary?.main || '#2F2F2F',
        },
    },
    errorIcon: {
        float: 'left',
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        width: '95%',
        margin: '0px 0px 0px 1rem !important',
        alignItems: 'center',
        display: 'flex',
        borderBottom: `1px solid ${theme.palette?.primary?.main || '#2F2F2F'}`,
        '& .outOfStockMsg': {
            display: 'inline-block',
            position: 'absolute',
            bottom: '8px',
            width: '100% !important',
            maxHeight: '1.2rem',
            padding: '0 !important',
            margin: '0 !important',
        },
    },
    itemDetails: {
        minWidth: '97px',
        padding: '0px !important',
        marginRight: '1rem',
        position: 'relative',
        [theme.breakpoints.down(415)]: {
            minWidth: '96px',
        },
        '& img': {
            aspectRatio: '97/104',
        },
    },
    icon: {
        marginLeft: '5px',
        width: '16px',
        fill: theme.palette.cms?.primary || theme.palette.secondary.main,
    },
    disableButton: {
        backgroundColor: theme.palette.footerLinkImgBg || '#E8F1ED',
        pointerEvents: 'none',
        '&:hover': {
            backgroundColor: theme.palette.footerLinkImgBg || '#E8F1ED',
        },
    },
    minicartFonts: {
        fontFamily: theme.palette.slideOutMiniCartFont || 'inherit',
    },
    seeTitle: {
        fontSize: '12px',
        fontFamily: theme.palette.slideOutMiniCartFlashSaleFont || 'inherit',
        cursor: 'pointer',
    },
    content: {
        minHeight: '80px',
    },
    resetBorderBottom: {
        borderBottom: 'unset',
    },
    alignBottomAfterScroll: {
        [theme.breakpoints.down(430)]: {
            bottom: '1rem',
        },
    },
    outOfStockMsg: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        padding: '0px 7px 0px 16px !important',
        display: 'inline-block',
        position: 'relative',
        '& p': {
            color: '#df3030',
            fontFamily: theme.palette.slideOutMiniCartFont || 'inherit',
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.2rem',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
        },
    },
    innerOOSMsgContent: {
        padding: '2px 10px 5px 0px',
        borderBottom: `1px solid ${theme.palette?.primary?.main || '#2F2F2F'}`,
    },
});

const FlashDealUI = ({
    classes, actionGetCart, actionAddToCart,
    history, content, handleCloseTo, heading, cartItems,
    addToCartErrorList, tracking, track, isCheckoutRecommendation, nbcuMovieData, productClickCallback, addToCartError, clearAddToCartErrorAction, isSalesforceRecsRendered,
}) => {
    const seed = useUIDSeed();
    const theme = useTheme();
    const [openModal, closeModal] = useState(false);
    const [partNumber, setPartNumber] = useState(false);
    const showProductOOSMsg = addToCartError?.match(/currently out of stock/ig);
    const showOOSMsgToOnlySFVariant = isSalesforceRecsRendered && showProductOOSMsg;
    const toggle = (sku = '') => {
        setPartNumber(sku);
        closeModal(!openModal);
    };
    if (!content || content?.length === 0) {
        handleCloseTo(false);
        return null;
    }

    // Get Cart action
    useEffect(() => {
        actionGetCart();
    }, []);

    // hide out of stock error message after add/remove product from cart
    useEffect(() => {
        if (showOOSMsgToOnlySFVariant) clearAddToCartErrorAction();
    }, [cartItems?.length]);

    const getErrorMessage = (productSku) => {
        let message = '';
        if (addToCartErrorList?.length > 0) {
            addToCartErrorList.forEach((errorMessage) => {
                if (skuBuilder(errorMessage.errorKey) === skuBuilder(productSku)) {
                    message = errorMessage.errorMessage;
                }
            });
        }
        return message;
    };

    // Item added to cart
    const handleContinue = (flashData) => {
        if (content.length > 0) {
            let productCode = flashData.id;
            if (productCode?.indexOf('-') >= 0) {
                productCode = productCode.split('-');
                productCode = productCode[productCode.length - 1];
            }

            const flashList = {
                brandCode: flashData?.brandId,
                productCode,
                quantity: 1,
                attributes: [],
            };

            // The products only needs firstname, lastname and email,
            // without send emails at the end of place order (example: market place vendor)
            if (
                flashData?.parentProduct?.categories?.includes('digital_delivery')
            ) {
                flashList.lineItemType = 'DDELVR';
            }

            if (
                flashData?.parentProduct?.categories?.includes('digital_products')
                || flashData?.name?.toLowerCase()?.includes('digital movie gift')
            ) {
                flashList.lineItemType = 'NBCU';
            }

            if (flashData?.productType === 'CLUB' || flashData?.productType === 'COLLECTION') {
                flashList.lineItemType = flashData.productType;
            }

            track({
                eventCategory: tracking?.tracking_event_category || tracking?.eventCategory,
                eventAction: tracking?.tracking_event_action || tracking?.eventAction,
                eventLabel: tracking?.tracking_event_label?.replace?.('<product_name>', flashData?.name) || flashData?.id,
            });
            productClickCallback(flashData?.partNumber);

            actionAddToCart({
                item: [{ ...flashList }],
                history,
                partNumber: flashData.id,
                enableMinicart: true,
                isMobile: false,
                eventType: 'Product Page',
            });
        }
        return null;
    };

    const returnSku = (getSkuData) => {
        const getDataInfo = cartItems?.some(
            (order) => order?.cartItems?.some(
                (item) => skuBuilder(item?.product?.productCode) === skuBuilder(getSkuData),
            ),
        );
        return getDataInfo;
    };
    const getGridListCols = () => {
        let col = 4.5;
        if (useMediaQuery(theme.breakpoints.down(1366))) {
            col = 4;
        }
        if (useMediaQuery(theme.breakpoints.down(1024))) {
            col = 3.5;
        }
        if (useMediaQuery(theme.breakpoints.down(415))) {
            col = 3;
        }
        return col;
    };
    // checking the movies product
    const isMovie =  content?.some((check) => check?.name?.toLowerCase()?.includes('digital movie gift'));

    const getGridListHeight = () => {
        let height = isMovie ?  228 : 208;
        if (useMediaQuery(theme.breakpoints.down(1366))) {
            height = 230;
        }
        if (isSalesforceRecsRendered) height = content.length > 3 ? 204 : 208;
        if (useMediaQuery(theme.breakpoints.down(1366)) && content.length < 3) {
            if (isSalesforceRecsRendered) height = 215;
        }
        if (useMediaQuery(theme.breakpoints.down(415))) {
            height = isMovie ?  227 : 222;
            if (isSalesforceRecsRendered && content.length > 3) height = isMovie ? 217 : 212;
        }
        if (useMediaQuery(theme.breakpoints.up(1366)) && content.length > 4) {
            if (isSalesforceRecsRendered) height = content.length > 3 ? 200 : 208;
        }

        return height;
    };

    let applyFonts = '';
    if (isCheckoutRecommendation) {
        applyFonts = classes.minicartFonts;
    }

    const movieSku = (sku) => nbcuMovieData?.some((getsku) => getsku?.sku === sku);

    return (
        <>
            <Grid container className={classes.root}>
                <Grid container className={classes.container}>
                    <Grid container className={classes.flashDealMain}>
                        <h2 className={`${classes.flashDealHeading} ${applyFonts}`}>{heading}</h2>
                        <span className={classes.flashDealClose}>
                            <IconButton data-testid="close-button" className={isCheckoutRecommendation ? classes.closeButtonCheckoutRecommendation : classes.closeButton} aria-label="close" onClick={() => handleCloseTo(false)}>
                                <CloseIcon />
                            </IconButton>
                        </span>
                    </Grid>
                    <GridList className={`${classes.gridList} ${showOOSMsgToOnlySFVariant ? classes.resetBorderBottom : ''}`} cols={getGridListCols()}  cellHeight={getGridListHeight()} aria-hidden="true" tabindex="-1">
                        {content?.map((flashData) => {
                        // Gets the value of sale price from the sku.
                            const retailPrice = flashData?.prices?.find((price) => price.type === 'retail')?.value;
                            const salePrice = flashData?.prices?.find((price) => price.type === 'sale')?.value;
                            const defaultPrice = `$${flashData?.prices?.[0]?.value}`;
                            const errorMessage = getErrorMessage(flashData?.id);
                            const isEnableCheckIcon  =  returnSku(flashData?.id)  || false;
                            return (
                                <Grid item data-testid="grid-item" key={seed(flashData)} data className={`${classes.itemDetails} ${applyFonts}`}>
                                    <ItemImage
                                        imagePath={flashData?.image?.path || ''}
                                        imageName={flashData?.image?.name || ''}
                                        typeImg="c"
                                        size="?width=130&auto=webp"
                                    />
                                    <div className={isMovie ? classes.content : ''}>
                                        {/* Product Details Start */}
                                        <Grid className={classes.prodTitle}>{ReactHtmlParser(flashData?.name || '')}</Grid>
                                        <Grid>
                                            <span className={classes.setItemLayout}>
                                                {salePrice && retailPrice && salePrice !== retailPrice
                                                    ? (
                                                        <>
                                                            <s>
                                                                ${retailPrice}
                                                            </s>
                                                            <span className={classes.salePrice}>
                                                                ${salePrice}
                                                            </span>
                                                        </>
                                                    )
                                                    : <span className={isCheckoutRecommendation ? classes.defaultPriceCheckoutRecommendation : classes.defaultPrice}> {defaultPrice}</span>}
                                            </span>
                                        </Grid>
                                        { movieSku(flashData?.partNumber) && (<Grid><span role="presentation" onClick={() => toggle(flashData?.partNumber)}  className={classes.seeTitle}> See titles</span></Grid>)}
                                    </div>
                                    <div className={classes.errorContainer}>
                                        {errorMessage
                                            && (
                                                <Typography
                                                    align="left"
                                                    variant="body2"
                                                    className={classes.errorMsg}
                                                >
                                                    <Error className={classes.errorIcon} color="error" />
                                                    <span> {ReactHtmlParser(errorMessage)} </span>
                                                </Typography>
                                            )}
                                    </div>
                                    <div>
                                        <div
                                            id="btnAddToCart"
                                            data-test="btn-add-to-cart"
                                            color="primary"
                                            className={`${classes.buttonContinue} ${isEnableCheckIcon ? classes.disableButton : ''}`}
                                            onKeyDown={() => { }}
                                            tabIndex={-1}
                                            role="button"
                                            onClick={() => handleContinue(flashData)}
                                        >
                                            Add {isEnableCheckIcon && <CheckIcon className={classes.icon} />}
                                        </div>
                                    </div>

                                </Grid>

                            );
                        })}
                    </GridList>
                </Grid>
                {nbcuMovieData?.map((wrapupItem) => (
                wrapupItem?.sku === partNumber  && <WrapUpModal modalData={wrapupItem} closeModal={toggle} openModal={openModal} isSlideOpen />
                ))}
            </Grid>
            {showOOSMsgToOnlySFVariant && (
                <Grid container className={`${classes.outOfStockMsg} ${content?.length === 3 ? classes.alignBottomAfterScroll : ''} outOfStockMsg`}>
                    <div className={`${classes.innerOOSMsgContent}`}>
                        <p>Sorry, the product you selected is out of stock</p>
                    </div>
                </Grid>
            )}
        </>
    );
};
FlashDealUI.propTypes = {
    classes: object.isRequired,
    history: object.isRequired,
    heading: string.isRequired,
    actionAddToCart: func.isRequired,
    content: arrayOf(shape({
        brand: string.isRequired,
        brandId: string.isRequired,
        name: string.isRequired,
        partNumber: string.isRequired,
        image: shape({
            name: string.isRequired,
            path: string.isRequired,
        }).isRequired,
    })).isRequired,
    cartItems: array,
    actionGetCart: func.isRequired,
    handleCloseTo: func.isRequired,
    addToCartErrorList: object,
    tracking: shape({
        eventCategory: '',
        eventAction: '',
    }),
    track: func,
    productClickCallback: func,
    isCheckoutRecommendation: bool,
    nbcuMovieData: arrayOf(arrayOf(shape({
        sku: string.isRequired,
        nbcu_see_titles: string.isRequired,
        info_on_hover: string.isRequired,
    }))),
    addToCartError: string,
    clearAddToCartErrorAction: func.isRequired,
    isSalesforceRecsRendered: bool,
};

FlashDealUI.defaultProps = {
    addToCartErrorList: {},
    cartItems: [],
    tracking: {},
    track: () => {},
    productClickCallback: () => {},
    isCheckoutRecommendation: false,
    nbcuMovieData: [],
    addToCartError: null,
    isSalesforceRecsRendered: false,
};

const mapStateToProps = (state) => ({
    addToCartError: getAddToCartError(state),
    addToCartErrorList: getAddToCartErrorList(state),
    cartItems: getRecipientsExcludePassport(state),
});

const mapDispatchToProps = (dispatch) => ({
    actionAddToCart: bindActionCreators(addToCartNewCartServices, dispatch),
    actionGetCart: bindActionCreators(getCart, dispatch),
    track: bindActionCreators(trackEvent, dispatch),
    clearAddToCartErrorAction: bindActionCreators(clearAddToCartError, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FlashDealUI)));
